import './Dashboard.css'
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Line } from "react-chartjs-2";
import { Skeleton } from 'antd';
 
Chart.register(CategoryScale);
 
const ChartContainer = ({ data,statsLoading }) => {
 
  return (
    <div className='chart-div'>
      {statsLoading? <Skeleton animation="wave" height={39} /> :
        data &&
        <Line
        height='374px'
        width='866px'
          data={{
            labels: Object.keys(data),
            datasets: [
              {
                fill: true,
                label: 'Sales Per Month',
                data: Object.values(data),
                backgroundColor: (context) => {
                  const ctx = context.chart.ctx;
                  const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                  gradient.addColorStop(0, "#434CE5");
                  gradient.addColorStop(1, "rgba(67, 76, 229, 0.00)");
                  return gradient;
                },
                borderColor: '#434CE5'
                // backgroundColor: 'linear-gradient(red, yellow, blue)'
              }
            ],
          }}
        />
      }
    </div>
  )
}

export default ChartContainer;