import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDashboardStats } from "../helper/config/graphqlQueries";
import axios from "axios";
import { ApiStatics } from "../helper/util/apiStatics";

const initialState = {
  approvedInvoices: 0,
  pendingInvoices: 0,
  completedInvoices: 0,
  gstPayable: 0,
  salesByStarClient: 0,
  starClient: "",
  totalSales: 0,
  chart: [],
  loading: false,
};

const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStats.pending, (state, _) => {
        state.loading = true;
      })
      .addCase(getStats.fulfilled, (state, { payload }) => {
        if (payload.success) {
          console.log("payload.data", payload.data);
          const {
            gstPayable,
            approvedInvoices,
            pendingInvoices,
            completedInvoices,
            totalSales,
            starClient,
          } = payload.data;

          state.approvedInvoices = approvedInvoices;
          state.pendingInvoices = pendingInvoices;
          state.completedInvoices = completedInvoices;
          state.gstPayable = gstPayable;
          state.salesByStarClient = totalSales;
          state.starClient = "Indocount";
          state.totalSales = totalSales;
          // state.chart = chart
          state.loading = false;
        }
      })
      .addCase(getCharts.pending, (state, _) => {
        state.loading = true;
      })
      .addCase(getCharts.fulfilled, (state, { payload }) => {
        console.log("payload.data in charts", payload.data);

        //  getCharts
        state.chart = payload.data;
        state.loading = false;
      });
  },
});

export const getStats = createAsyncThunk(
  "stats/get",
  async ({ startDate, endDate }) => {
    try {
      const token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      let resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/${ApiStatics.home.home}/${
          ApiStatics.stats.stats
        }?${startDate ? `&fromDate=${startDate}` : ""}${
          startDate ? `&toDate=${endDate}` : ""
        }`,
        config
      );
      console.log("respoo", resp.data.data);
      let approvedInvoices = 0;
      let pendingInvoices = 0;
      let completedInvoices = 0;

      resp.data.data.stats?.map((stat) => {
        if (stat.status === "PENDING") {
          pendingInvoices = stat.count;
        } else if (stat.status === "APPROVED") {
          approvedInvoices = stat.count;
        } else {
          completedInvoices = stat.count;
        }
      });

      return {
        success: true,
        data: {
          ...resp.data.data,
          approvedInvoices,
          pendingInvoices,
          completedInvoices,
        },
      };
    } catch (error) {
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
export const getCharts = createAsyncThunk(
  "stats/getCharts",
  async ({ startDate, endDate }) => {
    try {
      const token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      let resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/${ApiStatics.home.home}/${
          ApiStatics.chart.chart
        }?${startDate ? `&fromDate=${startDate}` : ""}${
          startDate ? `&toDate=${endDate}` : ""
        }`,
        config
      );
      console.log("charts stat", resp.data.data);
      return {
        success: true,
        data: resp.data.data.monthlySales,
      };
    } catch (error) {
      return {
        success: false,
        error: error.message,
      };
    }
  }
);

export default statsSlice.reducer;

export const {} = statsSlice.actions;
