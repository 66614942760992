export const employeeFields = [
  {
    id: 1,
    name: "fullName",
    label: "Full Name",
    type: "text",
    placeholder: "Enter full name",
  },
  {
    id: 2,
    name: "email",
    label: "Email",
    type: "text",
    placeholder: "Enter email",
  },
  {
    id: 3,
    name: "phoneNumber",
    label: "Phone Number",
    type: "text",
    placeholder: "Enter phone number",
  },
  {
    id: 4,
    name: "role",
    label: "Role",
    type: "select",
    placeholder: "Select role for employee",
    options: [
      {
        label: "Admin",
        value: "ADMIN",
      },
      {
        label: "Employee",
        value: "EMPLOYEE",
      },
    ],
  },
];
