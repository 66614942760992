import React, { useEffect } from "react";
import "./Login.css";
import "../../App.css";
import LoginGraphic from "../../assets/login-graphic.png";
import Logo from "../Logo/Logo";
import { Box, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import { loginUser } from "../../store/userSlice";
import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  const dispatch = useDispatch();
  const { loading, error, success } = useSelector((state) => state.user);

  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { register, handleSubmit, formState, control, setError } = form;
  const { errors } = formState;

  const onSubmit = (data) => {
    dispatch(loginUser(data));
  };

  useEffect(() => {
    if (error) {
      setError("password", { type: "custom", message: error });
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      window.location.href = "/dashboard?page=1";
    }
  }, [success]);

  return (
    <div className="login-parent">
      <div className="login-left-background">
        <Logo />
        <div style={{ marginBottom: 40 }} />
        <h1 className="login-promo-text">
          Seamless Shipping <span>Solutions</span> for Your <span>Cargo</span>{" "}
          Needs
        </h1>
        <div style={{ marginBottom: 60 }} />
        <img src={LoginGraphic} style={{ height: 400, alignSelf: "center" }} />
      </div>
      <div className="login-right-background">
        <h4>Welcome Back!</h4>
        <div style={{ marginBottom: 16 }} />
        <p className="login-welcome-back-description">
          Please login using your registered email & password.
        </p>
        <div style={{ marginBottom: 40 }} />
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 0, width: "100%" },
            "& .MuiInputBase-input ": {
              backgroundColor: "#FBFBFB",
              borderColor: "#DBDDF1",
              borderRadius: 8,
            },
          }}
          style={{
            width: 500,
            display: "flex",
            flexDirection: "column",
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            fullWidth
            required
            label="Email"
            type="email"
            variant="outlined"
            {...register("email", {
              required: "Email is required",
            })}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <div style={{ height: 24 }} />
          <TextField
            fullWidth
            required
            label="Password"
            type="password"
            variant="outlined"
            {...register("password", {
              required: "Password is required",
            })}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
          <div style={{ height: 40 }} />
          <LoadingButton
            loading={loading}
            className="app-button"
            type="submit"
            variant="contained"
            color="primary"
          >
            Login
          </LoadingButton>
        </Box>
      </div>
    </div>
  );
};

export default Login;
