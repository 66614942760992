import { Tooltip } from "@mui/material";

export const statsContainerData = [
  {
    color: "#4295DB",
    icon: "pending-invoices.png",
    title: "Pending Invoices",
    dataKey: "pendingInvoices",
    currency: false,
  },
  {
    color: "#EF8432",
    icon: "approved-invoices.svg",
    title: "Approved Invoices",
    dataKey: "approvedInvoices",
    currency: false,
  },
  {
    color: "#F6C844",
    icon: "total-sales.svg",
    title: "Total Sales (22-23)",
    dataKey: "totalSales",
    currency: true,
  },
  {
    color: "#52B538",
    icon: "gst-payable.svg",
    title: "GST Payable (Jul-Sep)",
    dataKey: "gstPayable",
    currency: true,
  },
  {
    color: "#DB4242",
    icon: "star-client.svg",
    title: "Star Client",
    dataKey: "starClient",
    currency: false,
  },
  {
    color: "#4264DB",
    icon: "sales-star-client.svg",
    title: "Completed Invoices",
    dataKey: "completedInvoices",
    currency: false,
  },
];

export const reportValuesEntries = [
  {
    label: "Invoice Number",
    value: "invoiceNumbers",
  },
  {
    label: "Type",
    value: "type",
  },
  {
    label: "Container Number",
    value: "containerNumber",
  },
  {
    label: "Number Of Packages",
    value: "numberOfPackages",
  },
  {
    label: "Third Party Company",
    value: "thirdPartyCompany",
  },
  {
    label: "Shipping Bill Numbers",
    value: "shippingBillNumbers",
  },
  {
    label: "Place Of Discharge",
    value: "placeOfDischarge",
  },
  {
    label: "Vessel Name",
    value: "vesselName",
  },
  {
    label: "CBM",
    value: "cbm",
  },
];

export const reportValuesCharges = [
  {
    label: "Agency",
    value: "agency",
  },
  {
    label: "Approval",
    value: "approval",
  },
  {
    label: "Buffer",
    value: "buffer",
  },
  {
    label: "Certification Of Invoice",
    value: "certificationOfInvoice",
  },
  {
    label: "CustomPenalty",
    value: "customPenalty",
  },
  {
    label: "DBK",
    value: "dbk",
  },
  {
    label: "Loading & Unloading",
    value: "loadingUnloading",
  },
  {
    label: "Measurement",
    value: "measurement",
  },
  {
    label: "Miscellaneous",
    value: "misc",
  },
  {
    label: "Port",
    value: "port",
  },
  {
    label: "Sorting",
    value: "sorting",
  },
  {
    label: "S Bill Cancellation",
    value: "sBillCancellation",
  },
  {
    label: "As Per Receipts Enclosed",
    value: "asPerReceiptsEnclosed",
  },
  {
    label: "Opening & Repacking",
    value: "openingRepacking",
  },
  {
    label: "Seal",
    value: "seal",
  },
  {
    label: "Unloading",
    value: "unloading",
  },
  {
    label: "W/H N",
    value: "whn",
  },
  {
    label: "Pvt W/H Transport",
    value: "pvtWhTransport",
  },
  {
    label: "Pvrt Move W/H",
    value: "pvrtMoveWh",
  },
  {
    label: "Sticker Printing",
    value: "stickerPrinting",
  },
  {
    label: "MMD3",
    value: "mmd3",
  },
  {
    label: "Fumigation",
    value: "fumigation",
  },
];
