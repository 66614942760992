import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Select,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import React from "react";
import avatar from "../../assets/form-avatar.svg";
import Paragraph from "antd/es/typography/Paragraph";
import { employeeFields } from "../../helper/util/StaticData";

const EmployeeFormLine = ({
  label,
  name,
  type,
  options,
  formik,
  placeholder,
  handleRole,
}) => (
  <div className="employee-form-line">
    <Paragraph>{label}</Paragraph>
    {type === "text" ? (
      <TextField
        name={name}
        fullWidth
        size="small"
        placeholder={placeholder}
        value={formik.values[name]}
        onChange={formik.handleChange}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
      />
    ) : (
      <Select
        sx={{ width: "100%" }}
        size="small"
        value={formik.values[name]}
        onChange={(e) => formik.setFieldValue("role", e.target.value)}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
      >
        {options?.map(({ label, value }) => (
          <MenuItem value={value}>{label}</MenuItem>
        ))}
      </Select>
    )}
  </div>
);
const AddEmployee = ({ open, handleClose, formik, loading }) => {
  // const [role,setRole]=useState('employee')

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="Add/Edit Employee"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="add-dialog-title"
        sx={{ display: "flex", fontSize: "24px" }}
      >
        <img
          src={avatar}
          alt="avatar"
          width="32px"
          style={{ marginRight: "5px" }}
        />{" "}
        Add Employee
      </DialogTitle>
      <Divider />
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container className="add-form-container" spacing={2}>
            {employeeFields.map((detail) => (
              <Grid item md={6}>
                <EmployeeFormLine
                  {...detail}
                  formik={formik}
                  //   setRole={setRole}
                  //   role={role}
                />
              </Grid>
            ))}
          </Grid>

          <DialogActions sx={{ mt: 1 }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit" disabled={loading}>
              Add Employee
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
      <Divider />
    </Dialog>
  );
};

export default AddEmployee;
