import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isAuth } from "../auth";
import { useDispatch } from "react-redux";

export const PrivateRoute = ({ component: Component, ...rest }) => {

  const dispatch = useDispatch();

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    isAuth(dispatch) ? <Outlet /> : <Navigate to='/' />
  );
};