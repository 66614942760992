import { useDispatch, useSelector } from "react-redux";
import Logo from "../Logo/Logo";
import "./Navbar.css";
import {
  MenuItem,
  Select,
  IconButton,
  FormControl,
  InputLabel,
} from "@mui/material";
import SideMenu from "../HamMenu/SideMenu";
import { DatePicker } from "antd";
import {
  updateDateFilter,
  updateFilterTrigger,
  updateFinancialYear,
} from "../../store/statusReportSlice";

const Navbar = () => {
  const { data } = useSelector((state) => state.user);
  const { filters } = useSelector((state) => state.statusReports);
  const dispatch = useDispatch();
  const handleYear = (e) => {
    const startDate =
      filters.financialYear === "24 - 25"
        ? "2024-04-01T11:25:56.908Z"
        : "2023-04-01T11:25:56.908Z";
    const endDate =
      filters.financialYear === "24 - 25"
        ? "2025-03-31T11:25:56.908Z"
        : "2024-03-31T11:25:56.908Z";

    const dates = [startDate, endDate];
    dispatch(updateFilterTrigger("finYear"));
    dispatch(updateDateFilter(dates));
    dispatch(updateFinancialYear(e.target.value));
  };

  // dispatch(updateFinancialYear(dateString));

  return (
    <div className="navbar-div">
      <div style={{ display: "flex", alignItems: "center", gap: "40px" }}>
        <div className="hamburger-icon">
          <IconButton className="hamburger-icon">
            <SideMenu />
          </IconButton>
        </div>
        <Logo />
      </div>
      <div className="navbar-user">
        {/* <DatePicker onChange={onChange} picker="year" size="large" />
         */}
        <FormControl fullWidth>
          <InputLabel id="finYear-select">Financial Year</InputLabel>
          <Select
            labelId="finYear-select"
            id="finYear-select"
            value={filters.financialYear}
            label="Financial Year"
            onChange={handleYear}
          >
            <MenuItem value="24 - 25">FY 24-25</MenuItem>
            <MenuItem value="23 - 24">FY 23-24</MenuItem>
          </Select>
        </FormControl>
        <div className="navbar-avatar">{data.name[0]}</div>
        {data.email}
      </div>
    </div>
  );
};

export default Navbar;
