import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import {
  addStatusReportEntry,
  approveStatusReportEntry,
  fetchStatusReport,
  fetchStatusReportDistinctPlaceOfDischarge,
  fetchSummaryReport,
  searchStatusReportQuery,
  updateStatusReportEntry,
} from "../helper/config/graphqlQueries";
import { createInvoice, downloadInvoice } from "../helper/util/Invoice";
import axios from "axios";
import { ApiStatics } from "../helper/util/apiStatics";

const initialState = {
  list: [],
  loading: false,
  createLoading: false,

  error: null,
  success: false,
  tokenExpired: false,
  placesOfDischarge: [],
  cfsAgencies: [],
  tableLoading: false,
  total: 0,
  loaders: {
    downloadSummaryReport: false,
    tableActions: false,
  },
  feedback: {
    summaryReportAvailable: false,
    summaryReportUrl: "",
  },
  filters: {
    trigger: "finYear",
    financialYear: "24 - 25",
    dates: [],
    statuses: [],
  },
};

const statusReportSlice = createSlice({
  name: "statusReport",
  initialState,
  reducers: {
    updateDateFilter(state, action) {
      state.filters.dates = action.payload;
    },
    updateFinancialYear(state, action) {
      state.filters.financialYear = action.payload;
    },
    updateFilterTrigger(state, action) {
      state.filters.trigger = action.payload;
    },
    updateStatusFilter(state, { payload }) {
      state.filters.statuses = payload;
    },
    updateSummaryReportVars(state, _) {
      state.loaders.downloadSummaryReport = false;
      state.feedback.summaryReportAvailable = false;
      state.feedback.summaryReportUrl = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllStatusReportEntries.fulfilled, (state, action) => {
        if (action.payload?.success) {
          console.log("action.payload", action.payload.data);
          const newData = action.payload.data.map((data) => ({
            ...data,
            key: data.id,
          }));
          state.loading = false;
          state.list = newData;
          // state.list = action.payload.data;
          state.total = action.payload.total;
          state.placeOfDischarge = action.payload.placeOfDischarge;
          state.cfsAgencies = action.payload.cfsAgencies;
          state.tableLoading = false;
          state.success = false;
        } else {
          state.tokenExpired = true;
        }
      })
      .addCase(getAllStatusReportEntries.pending, (state, action) => {
        state.loading = true;
        state.tableLoading = true;
      })
      .addCase(searchStatusReport.fulfilled, (state, action) => {
        console.log("search action payload", action.payload);
        if (action.payload.success) {
          state.loading = false;
          state.list = action.payload.data;
          state.total = action.payload.count;
          state.placeOfDischarge = action.payload.placeOfDischarge;
          state.cfsAgencies = action.payload.cfsAgencies;
          state.tableLoading = false;
          state.success = false;
        } else {
          state.tokenExpired = true;
        }
      })
      .addCase(searchStatusReport.pending, (state, action) => {
        state.loading = true;
        state.tableLoading = true;
      })

      .addCase(addNewStatusReportEntry.pending, (state, action) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(addNewStatusReportEntry.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.list = [];
      })

      .addCase(updateNewStatusReportEntry.pending, (state, action) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(updateNewStatusReportEntry.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.list = [];
      })
      .addCase(getPlacesOfDischarge.pending, (state, { payload }) => {
        state.success = false;
      })
      .addCase(getPlacesOfDischarge.fulfilled, (state, { payload }) => {
        state.placesOfDischarge = payload.data;
        // state.placeOfDischarge = payload.data
      })

      .addCase(approveEntry.pending, (state, action) => {
        console.log("Approve Ending Pending");
        state.loaders.tableActions = true;
        state.loading = true;
        state.success = false;
      })
      .addCase(approveEntry.fulfilled, (state, action) => {
        state.loaders.tableActions = false;
        state.loading = false;
        state.success = true;
        state.list = [];
      })

      .addCase(createInvoiceFromEntry.pending, (state, action) => {
        state.loaders.tableActions = true;
        state.loading = true;
        state.success = false;
      })
      .addCase(createInvoiceFromEntry.fulfilled, (state, action) => {
        state.loaders.tableActions = false;
        state.loading = false;
        state.success = true;
        state.list = [];
      })

      .addCase(downloadSummaryReport.pending, (state, _) => {
        state.loaders.downloadSummaryReport = true;
        state.feedback.summaryReportAvailable = false;
      })
      .addCase(downloadSummaryReport.fulfilled, (state, { payload }) => {
        state.loaders.downloadSummaryReport = false;
        state.feedback.summaryReportAvailable = true;
        state.feedback.summaryReportUrl = payload.url;
      });
  },
});

export const getAllStatusReportEntries = createAsyncThunk(
  "statusReport/get",
  async (
    { page, limit, startDate, endDate, status, financialYear },
    { getState }
  ) => {
    console.log("getting financialYear ", financialYear);
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // ${financialYear ? `&financialYear=${financialYear}` : ""}
      let resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/${
          ApiStatics.statusReports.statusReports
        }?${page ? `page=${page - 1}` : ""}${limit ? `&limit=${limit}` : ""}${
          startDate ? `&startDate=${startDate}` : ""
        }${endDate ? `&endDate=${endDate}` : ""}&${
          status ? `&status=${status}` : ""
        }&sort=-sNo`,
        config
      );

      console.log("resp of stat reports", resp);
      return {
        success: resp.data.status === "success",
        data: resp.data.data.data,
        total: resp.data.total,
      };
    } catch (error) {
      console.error("Error", error);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);

export const addNewStatusReportEntry = createAsyncThunk(
  "statusReport/add",
  async (data) => {
    try {
      await addStatusReportEntry(data);
      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        error: error.message,
      };
    }
  }
);

export const updateNewStatusReportEntry = createAsyncThunk(
  "statusReport/update",
  async ({ id, data }) => {
    try {
      const token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      let resp = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/${ApiStatics.statusReports.statusReports}/${id}`,
        data,
        config
      );
      let resp2 = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/${ApiStatics.charges.charges}/${data.chargesId}`,
        data.chargesObj,
        config
      );
      return {
        success: true,
      };
    } catch (error) {
      console.log("error", error);
      // return {
      //   success: false,
      //   error: error.message,
      // };
    }
  }
);

export const approveEntry = createAsyncThunk(
  "statusReport/approve",
  async ({ id }) => {
    try {
      const token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      let resp = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/${ApiStatics.statusReports.statusReports}/${id}`,
        { status: "APPROVED" },
        config
      );

      return {
        success: true,
      };
    } catch (error) {
      console.log("error", error);
      // return {
      //   success: false,
      //   error: error.message,
      // };
    }
  }
);
export const searchStatusReport = createAsyncThunk(
  "statusReport/searchStatusReport",
  async ({ query }) => {
    console.log("query", query);
    try {
      const token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // const data = await searchStatusReportQuery({ query });
      let resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/${ApiStatics.home.home}/${ApiStatics.search.search}?query=${query}`,
        config
      );

      console.log("search data", resp.data?.data);
      return {
        data: resp.data?.data.statusReports,
        success: true,
      };
    } catch (error) {
      console.log("error", error);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);

export const createInvoiceFromEntry = createAsyncThunk(
  "statusReport/createInvoice",
  async ({ id, internalInvoiceNumber, setInvoiceDialog }) => {
    try {
      const token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      let resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/${ApiStatics.invoices.invoices}`,
        { statusReportId: id, internalInvoiceNumber },
        config
      );
      console.log("downolad response", resp.data.data.downloadUrl);
      window.open(resp.data.data.downloadUrl, "_blank");
      setInvoiceDialog(false);
      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
export const createMultiInvoices = createAsyncThunk(
  "statusReport/createMultiInvoices",
  async ({ ids, setInvoiceDialog, internalInvoiceNumber, navigate }) => {
    try {
      const token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      let resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/${ApiStatics.invoices.invoices}/multiple`,
        { ids, startInvoiceNum: internalInvoiceNumber },
        config
      );
      navigate(0);
      // setInvoiceDialog(false);
      return {
        success: true,
      };
    } catch (error) {
      navigate(0);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);

export const downloadEntryInvoice = createAsyncThunk(
  "statusReport/downloadInvoice",
  async ({ id }) => {
    console.log("data in download", id);
    try {
      // await downloadInvoice(data.id);
      const token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      let resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/${ApiStatics.invoices.invoices}/${id}`,
        config
      );
      console.log("downolad response", resp.data.data.downloadUrl);
      window.open(resp.data.data.downloadUrl, "_blank");
      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        error: error.message,
      };
    }
  }
);

export const downloadSummaryReport = createAsyncThunk(
  "statusReport/downloadStatusReport",
  async (data) => {
    try {
      // let resp = await fetchSummaryReport(data);
      console.log("downloadSummaryReport", data);
      const token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      let resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/${ApiStatics.home.home}/${ApiStatics.summaryReport.summaryReport}`,
        data,
        config
      );
      console.log("downolad response", resp.data.data.downloadUrl);
      window.open(resp.data.data.downloadUrl, "_blank");
      return {
        success: true,
        url: resp.data.downloadSummaryReport.downloadUrl,
      };
    } catch (error) {
      return {
        success: false,
        error: error.message,
      };
    }
  }
);

export const getPlacesOfDischarge = createAsyncThunk(
  "statusReport/getPlacesOfDischarge",
  async (data) => {
    try {
      const token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      let resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/${ApiStatics.statusReports.statusReports}/${ApiStatics.placesOfDischarge.placesOfDischarge}`,
        config
      );
      console.log("resp of discharge places", resp);
      return {
        data: resp.data.data.places,
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        error: error.message,
      };
    }
  }
);

export default statusReportSlice.reducer;

export const {
  updateDateFilter,
  updateFinancialYear,
  updateStatusFilter,
  updateSummaryReportVars,
  updateFilterTrigger,
} = statusReportSlice.actions;
