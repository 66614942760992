import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import statusReportSlice from "./statusReportSlice";
import statsSlice from "./statsSlice";

const store = configureStore({
  reducer: {
    user: userSlice,
    statusReports: statusReportSlice,
    stats: statsSlice
  }
})

export default store;