export const ApiStatics = {
  auth: {
    auth: "auth",
  },
  invoices: {
    invoices: "invoices",
  },
  charges: {
    charges: "charges",
  },
  home: {
    home: "home",
  },
  stats: {
    stats: "stats",
  },
  placesOfDischarge: {
    placesOfDischarge: "places-of-discharge",
  },
  statusReports: {
    statusReports: "status-reports",
  },
  summaryReport: {
    summaryReport: "summary-report",
  },
  search: {
    search: "search",
  },
  chart: {
    chart: "chart",
  },
  users: {
    users: "users",
  },
};
