import { useEffect, useRef } from "react";

export const getFormattedCurrency = (data) => `₹${Intl.NumberFormat('en-IN').format(data)}`

export const getFormattedNumber = (data) => Intl.NumberFormat('en-IN').format(data)

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};