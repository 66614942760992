import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "../../assets/dashboard-icon.png";
import EmployeesIcon from "../../assets/employee_white.svg";

const SideMenu = () => {
  const menu = [
    {
      title: "Dashboard",
      link: "/dashboard",
      icon: DashboardIcon,
    },
    {
      title: "Employees",
      link: "/employees",
      icon: EmployeesIcon,
    },
    // {
    //   "title": "Clients",
    //   "link": "/clients",
    //   "icon": ClientsIcon
    // }
  ];

  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div>
      <IconButton onClick={toggleDrawer} color="inherit">
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        PaperProps={{
          style: { width: "250px", backgroundColor: "#434CE5", color: "white" },
        }}
      >
        <div style={{ padding: "16px" }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              fontFamily: "Raleway",
              justifyContent: "center",
              display: "flex",
              fontWeight: "800",
            }}
          >
            AIRLIFT
          </Typography>
        </div>
        <List
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          {menu.map((val) => (
            <ListItem
              onClick={() => {
                if (window.location.pathname == val.link) {
                  setOpen(false);
                  return;
                }
                window.location.href = val.link;
              }}
              className="pointer"
            >
              <ListItemIcon>
                <img src={val.icon} />
              </ListItemIcon>
              <ListItemText primary={val.title} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default SideMenu;
