import * as yup from "yup";

export const userValidationSchema = yup.object({
  fullName: yup.string("Enter name").required("Name is required"),
  email: yup
    .string("Enter email")
    .email("Enter a valid email")
    .required("Email is required"),
  phoneNumber: yup
    .string("Enter phone number")
    .required("Phone number is required"),
  role: yup.string("Enter role").required("Role is required"),
});
