import "./App.css";
import Login from "./components/Login/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import { Provider } from "react-redux";
import store from "./store/store";
import { PrivateRoute } from "./helper/routes/PrivateRoute";
import Dashboard from "./components/Dashboard/Dashboard";
import Employees from "./Containers/Employees";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
          <Route path="/employees" element={<PrivateRoute />}>
            <Route path="/employees" element={<Employees />} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
