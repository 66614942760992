import React from 'react';
import logo from '../../assets/logo.png'
import './Logo.css'

const Logo = (props) => {

  const { size = 'large' } = props;

  return (
    <div className='logo-div'>
      <img src={logo} className='logo-image' />
      <h2>ACL | Admin Panel</h2>
    </div>
  )
}

export default Logo;