import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./index.scss";
import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Table } from "antd";
import editIcon from "../../assets/edit.svg";
import blockIcon from "../../assets/block.svg";
import AddEmployee from "./AddEmployee";
import { useFormik } from "formik";
import { userValidationSchema } from "../../helper/util/formikSchema";
import { addUser, getAllUsers } from "../../store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BlockEmployee from "./BlockEmployee";

const initialValues = {
  fullName: "",
  email: "",
  phoneNumber: "",
  role: "EMPLOYEE",
};
const Employees = () => {
  const { users, loading, actionLoading, total } = useSelector(
    (state) => state.user
  );
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [userId, setId] = useState("");
  const [openBlock, setBlock] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues,
    validationSchema: userValidationSchema,
    onSubmit: (values) => {
      console.log("values", values);
      dispatch(addUser({ values, toast, handleClose }));
    },
  });
  const handleNew = (values) => {
    formik.setValues({
      ...initialValues,
    });
    handleOpen();
  };
  const handleBlock = (row) => {
    // formik.setValues({
    //   id: row.id,
    // });
    setId(row.id);
    setBlock(true);
  };
  const closeBlock = () => setBlock(false);
  const handleEdit = (values) => {
    formik.setValues({
      ...values,
    });
    handleOpen();
  };
  const employeeColumns = [
    {
      title: "Full Name",
      dataIndex: "fullName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "",
      dataIndex: "",
      width: 100,
      render: (row) => (
        <>
          <div style={{ display: "flex", gap: "20px" }}>
            <Tooltip title="Block User" onClick={() => handleBlock(row)}>
              <img style={{ cursor: "pointer" }} src={blockIcon} alt="block" />
            </Tooltip>
            {/* <Tooltip title="Delete Entry">
              <DeleteOutlineIcon color="primary" />
            </Tooltip> */}
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getAllUsers({ page, limit }));
  }, [open, openBlock, page, limit]);

  return (
    <div className="employees-container">
      <Navbar />

      <div className="employee-table-container">
        <div className="table-container-header">
          <h6>Employees</h6>
          <Button
            className="app-button-small button-extra-padding"
            variant="contained"
            color="primary"
            onClick={handleNew}
          >
            <AddIcon /> Add Employee
          </Button>
        </div>
        <div className="table-container-table">
          <div className="status-report-table">
            <Table
              columns={employeeColumns}
              dataSource={users}
              loading={loading}
              pagination={{
                position: ["topRight"],
                defaultPageSize: 10,
                total,
                current: page,
                onChange: (newPage) => setPage(newPage),
                onShowSizeChange: (e, value) => setLimit(value),
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["10", "20", "30"],
                style: {
                  position: "sticky",
                  top: "53px",
                  zIndex: "23",
                  backgroundColor: "white",
                  marginTop: "0px",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                },
              }}
              sticky={true}
              //   loading={loading}
            />
            <div
              style={{
                width: "95%",
                display: "flex",
                justifyContent: "end",
                flexDirection: "column",
              }}
            ></div>
          </div>
        </div>
      </div>
      <AddEmployee
        open={open}
        handleClose={handleClose}
        formik={formik}
        loading={actionLoading}
      />
      <BlockEmployee open={openBlock} handleClose={closeBlock} id={userId} />
      <ToastContainer />
    </div>
  );
};

export default Employees;
