import { addLoggedInUsersData } from "../../store/userSlice";

export const isAuth = (dispatch) => {
  const payload = localStorage.getItem("token");
  const data = localStorage.getItem("data");
  if (payload != null || data != null) {
    dispatch(addLoggedInUsersData(JSON.parse(data)));
    return true;
  } else {
    return false;
  }
};
