import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  palette: {
    primary: {
      main: "#434CE5"
    },
    secondary: {
      main: "#434CE5"
    }
  },
  fontFamily: 'Inter'
});


root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);