import gql from "graphql-tag";
import { apolloClient } from "./apolloClient";

export const fetchStatusReport = (where, offset, limit) =>
  apolloClient.query({
    query: GET_STATUS_REPORTS,
    variables: { offset, limit, where },
  });

export const fetchSummaryReport = ({ entries, charges, custom, dates }) =>
  apolloClient.query({
    query: GET_SUMMARY_REPORT,
    variables: { entries, charges, custom, dates },
  });
export const searchStatusReportQuery = ({ query }) => 
  apolloClient.query({
    query: SEARCH_STATUS_REPORTS,
    variables: { query,multi:[query] },
  });


export const fetchDashboardStats = (where, offset, limit) =>
  apolloClient.query({
    query: GET_STATS,
  });

export const fetchStatusReportDistinctPlaceOfDischarge = () =>
  apolloClient.query({
    query: GET_STATUS_REPORTS_PLACE_DISCHARGE_DISTINCT,
  });

export const addStatusReportEntry = (data) =>
  apolloClient.mutate({
    mutation: ADD_STATUS_REPORT,
    variables: data,
  });

export const updateStatusReportEntry = (data) =>
  apolloClient.mutate({
    mutation: UPDATE_STATUS_REPORT,
    variables: data,
  });

export const approveStatusReportEntry = (data) =>
  apolloClient.mutate({
    mutation: APPROVE_STATUS_REPORT,
    variables: data,
  });

const GET_STATUS_REPORTS_PLACE_DISCHARGE_DISTINCT = gql`
  query GetAllPlaceOfDischarge {
    placeOfDischarge: statusreports(distinct_on: placeOfDischarge) {
      placeOfDischarge
    }
    cfsAgencies: statusreports(
      distinct_on: cfsAgency
      where: { cfsAgency: { _neq: "null", _nilike: "" } }
    ) {
      cfsAgency
    }
  }
`;

const GET_SUMMARY_REPORT = gql`
  query DownloadSummaryReport(
    $charges: [String]
    $custom: Boolean
    $dates: [String]
    $entries: [String]
  ) {
    downloadSummaryReport(
      charges: $charges
      custom: $custom
      dates: $dates
      entries: $entries
    ) {
      downloadUrl
    }
  }
`;

const GET_STATS = gql`
  query GetDashboardStats {
    getDashboardStats {
      approvedInvoices
      pendingInvoices
      gstPayable
      salesByStarClient
      starClient
      totalSales
      chart {
        key
        value
      }
    }
  }
`;

const GET_STATUS_REPORTS = gql`
  query GetAllStatusReports(
    $where: statusreports_bool_exp
    $offset: Int!
    $limit: Int!
  ) {
    statusreports_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    statusreports(
      where: $where
      limit: $limit
      offset: $offset
      order_by: { order: desc }
    ) {
      id
      invoiceNumbers
      created_at
      type
      containerNumber
      numberOfPackages
      thirdPartyCompany
      shippingBillNumbers
      placeOfDischarge
      bufferCharges
      portCharges
      invoice
      status
      vesselName
      charges
      cbm
      cfsAgency
      measurementCheck
      order
      statusreports_invoice {
        invoiceNumber
        gst
        totalWithoutGst
        from
      }
      statusreports_charges {
        agency
        approval
        buffer
        certificationOfInvoice
        customPenalty
        dbk
        id
        loadingUnloading
        measurement
        misc
        port
        sorting
        sBillCancellation
        asPerReceiptsEnclosed
        openingRepacking
        seal
        unloading
        whn
        pvtWhTransport
        pvrtMoveWh
        stickerPrinting
        mmd3
        fumigation
      }
    }
  }
`;

export const APPROVE_STATUS_REPORT = gql`
  mutation updateStatusReport($id: uuid!) {
    update_statusreports_by_pk(
      pk_columns: { id: $id }
      _set: { status: APPROVED }
    ) {
      id
    }
  }
`;
const SEARCH_STATUS_REPORTS = gql`
  query SearchRecords($query:String!,$multi:[String!]) {
    statusreports(
      limit: 10
      where: {
        _or: [
          
           { containerNumber: { _eq: $query } }
           { shippingBillNumbers: { _contains: $multi }}
           { invoiceNumbers: { _contains: $multi }}
           { statusreports_invoice :{ invoiceNumber : { _eq: $query } }}
           
        ]
        
      }
    ) {
      id
      invoiceNumbers
      created_at
      type
      containerNumber
      numberOfPackages
      thirdPartyCompany
      shippingBillNumbers
      placeOfDischarge
      bufferCharges
      portCharges
      invoice
      status
      vesselName
      charges
      cbm
      cfsAgency
      measurementCheck
      statusreports_invoice {
        invoiceNumber
        gst
        totalWithoutGst
        from
      }
      statusreports_charges {
        agency
        approval
        buffer
        certificationOfInvoice
        customPenalty
        dbk
        id
        loadingUnloading
        measurement
        misc
        port
        sorting
        sBillCancellation
        asPerReceiptsEnclosed
        openingRepacking
        seal
        unloading
        whn
        pvtWhTransport
        pvrtMoveWh
        stickerPrinting
        mmd3
        fumigation
      }
    }
  }
`;

const UPDATE_STATUS_REPORT = gql`
  mutation UpdateStatusReportEmployee(
    $id: uuid!
    $chargesId: uuid!
    $bufferCharges: numeric
    $status: String!
    $containerNumber: String
    $invoiceNumbers: [String!]
    $numberOfPackages: String
    $placeOfDischarge: String
    $portCharges: numeric
    $shippingBillNumbers: [String!]
    $thirdPartyCompany: String!
    $vesselName: String!
    $type: String
    $cbm: String
    $approvalCharges: numeric
    $comments: String
    $agencyCharges: numeric
    $certificationOfInvoiceCharges: numeric
    $customPenaltyCharges: numeric
    $dbkCharges: numeric
    $loadingUnloadingCharges: numeric
    $measurementCharges: numeric
    $miscCharges: numeric
    $sortingCharges: numeric
    $sBillCancellationCharges: numeric
    $asPerReceiptsEnclosedCharges: numeric
    $openingRepackingCharges: numeric
    $sealCharges: numeric
    $unloadingCharges: numeric
    $whnCharges: numeric
    $pvtWhTransportCharges: numeric
    $pvrtMoveWhCharges: numeric
    $stickerPrintingCharges: numeric
    $mmd3Charges: numeric
    $fumigationCharges: numeric
    $cfsAgency: String
    $measurementCheck: Boolean
  ) {
    update_statusreports_by_pk(
      pk_columns: { id: $id }
      _set: {
        containerNumber: $containerNumber
        invoiceNumbers: $invoiceNumbers
        numberOfPackages: $numberOfPackages
        placeOfDischarge: $placeOfDischarge
        portCharges: $portCharges
        shippingBillNumbers: $shippingBillNumbers
        thirdPartyCompany: $thirdPartyCompany
        vesselName: $vesselName
        type: $type
        cbm: $cbm
        comments: $comments
        status: $status
        cfsAgency: $cfsAgency
        measurementCheck: $measurementCheck
      }
    ) {
      id
    }
    update_charges_by_pk(
      pk_columns: { id: $chargesId }
      _set: {
        approval: $approvalCharges
        buffer: $bufferCharges
        port: $portCharges
        agency: $agencyCharges
        certificationOfInvoice: $certificationOfInvoiceCharges
        customPenalty: $customPenaltyCharges
        dbk: $dbkCharges
        loadingUnloading: $loadingUnloadingCharges
        measurement: $measurementCharges
        misc: $miscCharges
        sorting: $sortingCharges
        sBillCancellation: $sBillCancellationCharges
        asPerReceiptsEnclosed: $asPerReceiptsEnclosedCharges
        openingRepacking: $openingRepackingCharges
        seal: $sealCharges
        unloading: $unloadingCharges
        whn: $whnCharges
        pvtWhTransport: $pvtWhTransportCharges
        pvrtMoveWh: $pvrtMoveWhCharges
        stickerPrinting: $stickerPrintingCharges
        mmd3: $mmd3Charges
        fumigation: $fumigationCharges
      }
    ) {
      id
    }
  }
`;

const ADD_STATUS_REPORT = gql`
  mutation AddStatusReportEmployee(
    $bufferCharges: numeric!
    $containerNumber: String!
    $invoiceNumbers: [String!]
    $numberOfPackages: String!
    $placeOfDischarge: String!
    $portCharges: numeric!
    $shippingBillNumbers: [String!]
    $thirdPartyCompany: String!
    $vesselName: String!
    $type: String!
    $cbm: String!
    $approvalCharges: numeric!
    $comments: String!
  ) {
    insert_statusreports_one(
      object: {
        statusreports_charges: {
          data: {
            approval: $approvalCharges
            buffer: $bufferCharges
            port: $portCharges
          }
        }
        containerNumber: $containerNumber
        invoiceNumbers: $invoiceNumbers
        numberOfPackages: $numberOfPackages
        placeOfDischarge: $placeOfDischarge
        portCharges: $portCharges
        shippingBillNumbers: $shippingBillNumbers
        thirdPartyCompany: $thirdPartyCompany
        vesselName: $vesselName
        type: $type
        cbm: $cbm
        comments: $comments
      }
    ) {
      id
    }
  }
`;
