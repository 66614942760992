import {
  Button,
  IconButton,
  InputLabel,
  Select,
  TextField,
  MenuItem,
  FormControl,
  FormHelperText,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { DatePicker } from "antd";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewStatusReportEntry,
  getPlacesOfDischarge,
  updateNewStatusReportEntry,
} from "../../store/statusReportSlice";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

const AddOrEditEntryDialog = ({ open, handleClose, editData }) => {
  const dispatch = useDispatch();
  const [cfsOtherValue, setCfsOther] = useState("");

  const typeValues = ["FCL", "LCL"];
  const agencyValues = ["All Cargo", "Others"];

  const fclNumberOfPackagesValue = ["1x40", "1x20"];

  const entryFields = [
    {
      label: "Invoice Number",
      fieldName: "invoiceNumbers",
      required: true,
    },
    {
      label: "Container Number",
      fieldName: "containerNumber",
      required: false,
    },
    {
      label: "Type",
      fieldName: "type",
      required: true,
    },
    {
      label: "Number of packages",
      fieldName: "numberOfPackages",
      required: true,
    },
    {
      label: "Leo Date",
      fieldName: "leoDate",
      // required: true,
    },
    {
      label: "Third Party Company",
      fieldName: "thirdPartyCompany",
      required: true,
    },
    {
      label: "Place of Discharge",
      fieldName: "placeOfDischarge",
      required: true,
    },
    {
      label: "CFS Agency",
      fieldName: "cfsAgency",
      required: false,
    },
    {
      label: "Shipping Bill Numbers",
      fieldName: "shippingBillNumbers",
      required: true,
    },
    {
      label: "Vessel Name",
      fieldName: "vesselName",
      required: true,
    },
    {
      label: "Comments",
      fieldName: "comments",
      required: false,
    },

    {
      label: "Buffer Charges",
      fieldName: "bufferCharges",
      required: false,
    },
    {
      label: "Port Charges",
      fieldName: "portCharges",
      required: false,
    },
    {
      label: "CBM",
      fieldName: "cbm",
      required: false,
    },
    {
      label: "Approval Charges",
      fieldName: "approvalCharges",
      required: false,
    },
    {
      label: "Agency Charges",
      fieldName: "agencyCharges",
      required: false,
      autoCharge: true,
    },
    {
      label: "Certification Of Invoice Charges",
      fieldName: "certificationOfInvoiceCharges",
      required: false,
    },
    {
      label: "Custom Penalty",
      fieldName: "customPenaltyCharges",
      required: false,
    },
    {
      label: "DBK Charges",
      fieldName: "dbkCharges",
      required: false,
      autoCharge: true,
    },
    {
      label: "Loading/Unloading Charges",
      fieldName: "loadingUnloadingCharges",
      required: false,
    },
    {
      label: "Measurement Charges",
      fieldName: "measurementCharges",
      required: false,
      autoCharge: true,
    },
    {
      label: "Misc Charges",
      fieldName: "miscCharges",
      required: false,
    },
    {
      label: "Sorting Charges",
      fieldName: "sortingCharges",
      required: false,
      autoCharge: true,
    },

    {
      label: "S Bill Cancellation Charges",
      fieldName: "sBillCancellationCharges",
      required: false,
    },
    {
      label: "As Per Receipts Enclosed Charges",
      fieldName: "asPerReceiptsEnclosedCharges",
      required: false,
    },
    {
      label: "Opening Repacking & Charges",
      fieldName: "openingRepackingCharges",
      required: false,
    },
    {
      label: "Seal Charges",
      fieldName: "sealCharges",
      required: false,
    },
    {
      label: "Unloading Charges",
      fieldName: "unloadingCharges",
      required: false,
    },
    {
      label: "W/H n Charges",
      fieldName: "whnCharges",
      required: false,
    },
    {
      label: "PVT W/H & Transport Charges",
      fieldName: "pvtWhTransportCharges",
      required: false,
    },
    {
      label: "PVRT Move W/H Charges",
      fieldName: "pvrtMoveWhCharges",
      required: false,
    },
    {
      label: "Sticker Printing & Pasting Charges",
      fieldName: "stickerPrintingCharges",
      required: false,
    },
    {
      label: "MMD3 Charges VGM Charges",
      fieldName: "mmd3Charges",
      required: false,
    },
    {
      label: "Fumigation",
      fieldName: "fumigationCharges",
      required: false,
    },
    {
      label: "Measurement Charges",
      fieldName: "measurementCheck",
      required: false,
    },
  ];

  const form = useForm({
    defaultValues: {
      invoiceNumbers: [],
      containerNumber: "",
      numberOfPackages: "",
      thirdPartyCompany: "",
      cfsAgency: "",
      placeOfDischarge: "",
      shippingBillNumbers: [],
      vesselName: "",
      type: "",
      cbm: "",
      comments: "",
      measurementCheck: false,

      bufferCharges: "",
      portCharges: "",
      approvalCharges: "",
      agencyCharges: "",
      certificationOfInvoiceCharges: "",
      customPenaltyCharges: "",
      dbkCharges: "",
      loadingUnloadingCharges: "",
      measurementCharges: "",
      miscCharges: "",
      sortingCharges: "",
      sBillCancellationCharges: "",
      asPerReceiptsEnclosedCharges: "",
      openingRepackingCharges: "",
      sealCharges: "",
      unloadingCharges: "",
      whnCharges: "",
      pvtWhTransportCharges: "",
      pvrtMoveWhCharges: "",
      stickerPrintingCharges: "",
      mmd3Charges: "",
      fumigationCharges: "",
    },
  });

  useEffect(() => {
    if (editData != null) {
      let newData = JSON.parse(JSON.stringify(editData));

      newData.invoiceNumbers = newData.invoiceNumbers?.join(", ");
      newData.shippingBillNumbers = newData.shippingBillNumbers?.join(", ");

      newData.bufferCharges = newData.charges.buffer;
      newData.portCharges = newData.charges.port;
      newData.approvalCharges = newData.charges.approval;
      newData.agencyCharges = newData.charges.agency;
      newData.certificationOfInvoiceCharges =
        newData.charges.certificationOfInvoice;
      newData.customPenaltyCharges = newData.charges.customPenalty;
      newData.dbkCharges = newData.charges.dbk;
      newData.loadingUnloadingCharges = newData.charges.loadingUnloading;
      newData.measurementCharges = newData.charges.measurement;
      newData.miscCharges = newData.charges.misc;
      newData.sortingCharges = newData.charges.sorting;

      newData.sBillCancellationCharges = newData.charges.sBillCancellation;
      newData.asPerReceiptsEnclosedCharges =
        newData.charges.asPerReceiptsEnclosed;
      newData.openingRepackingCharges = newData.charges.openingRepacking;
      newData.sealCharges = newData.charges.seal;
      newData.unloadingCharges = newData.charges.unloading;
      newData.whnCharges = newData.charges.whn;
      newData.pvtWhTransportCharges = newData.charges.pvtWhTransport;
      newData.pvrtMoveWhCharges = newData.charges.pvrtMoveWh;
      newData.stickerPrintingCharges = newData.charges.stickerPrinting;
      newData.mmd3Charges = newData.charges.mmd3;
      newData.fumigationCharges = newData.charges.fumigation;
      newData.cfsAgency !== "All Cargo" && setCfsOther(newData.cfsAgency);
      newData.cfsAgency =
        newData.cfsAgency === "All Cargo" ? "All Cargo" : "Others";
      reset(newData);
    }
  }, []);

  const { register, handleSubmit, formState, control, setError, watch, reset } =
    form;
  const { errors } = formState;
  const { loading, error, success, placesOfDischarge, cfsAgencies } =
    useSelector((state) => state.statusReports);

  const onSubmit = (data) => {
    data.invoiceNumbers = returnCommaStringToArray(data.invoiceNumbers);
    data.shippingBillNumbers = returnCommaStringToArray(
      data.shippingBillNumbers
    );

    data.bufferCharges =
      data.bufferCharges?.length > 0 ? data.bufferCharges : null;
    data.portCharges = data.portCharges?.length > 0 ? data.portCharges : null;
    data.approvalCharges =
      data.approvalCharges?.length > 0 ? data.approvalCharges : null;

    if (editData != null) {
      data.status = "PENDING";

      data.chargesId = editData.charges.id;

      data.agencyCharges =
        data.agencyCharges?.length > 0 ? data.agencyCharges : null;
      data.certificationOfInvoiceCharges =
        data.certificationOfInvoiceCharges?.length > 0
          ? data.certificationOfInvoiceCharges
          : null;
      data.customPenaltyCharges =
        data.customPenaltyCharges?.length > 0
          ? data.customPenaltyCharges
          : null;
      data.dbkCharges = data.dbkCharges?.length > 0 ? data.dbkCharges : null;
      data.loadingUnloadingCharges =
        data.loadingUnloadingCharges?.length > 0
          ? data.loadingUnloadingCharges
          : null;
      data.measurementCharges =
        data.measurementCharges?.length > 0 ? data.measurementCharges : null;
      data.miscCharges = data.miscCharges?.length > 0 ? data.miscCharges : null;
      data.sortingCharges =
        data.sortingCharges?.length > 0 ? data.sortingCharges : null;

      data.sBillCancellationCharges =
        data.sBillCancellationCharges?.length > 0
          ? data.sBillCancellationCharges
          : null;
      data.asPerReceiptsEnclosedCharges =
        data.asPerReceiptsEnclosedCharges?.length > 0
          ? data.asPerReceiptsEnclosedCharges
          : null;
      data.openingRepackingCharges =
        data.openingRepackingCharges?.length > 0
          ? data.openingRepackingCharges
          : null;
      data.sealCharges = data.sealCharges?.length > 0 ? data.sealCharges : null;
      data.unloadingCharges =
        data.unloadingCharges?.length > 0 ? data.unloadingCharges : null;
      data.whnCharges = data.whnCharges?.length > 0 ? data.whnCharges : null;
      data.pvtWhTransportCharges =
        data.pvtWhTransportCharges?.length > 0
          ? data.pvtWhTransportCharges
          : null;
      data.pvrtMoveWhCharges =
        data.pvrtMoveWhCharges?.length > 0 ? data.pvrtMoveWhCharges : null;
      data.stickerPrintingCharges =
        data.stickerPrintingCharges?.length > 0
          ? data.stickerPrintingCharges
          : null;
      data.mmd3Charges = data.mmd3Charges?.length > 0 ? data.mmd3Charges : null;
      data.fumigationCharges =
        data.fumigationCharges?.length > 0 ? data.fumigationCharges : null;

      data.chargesObj = {
        approval: data.approvalCharges,
        buffer: data.bufferCharges,
        port: data.portCharges,
        agency: data.agencyCharges,
        certificationOfInvoice: data.certificationOfInvoiceCharges,
        customPenalty: data.customPenaltyCharges,
        dbk: data.dbkCharges,
        loadingUnloading: data.loadingUnloadingCharges,
        measurement: data.measurementCharges,
        misc: data.miscCharges,
        sorting: data.sortingCharges,
        sBillCancellation: data.sBillCancellationCharges,
        asPerReceiptsEnclosed: data.asPerReceiptsEnclosedCharges,
        openingRepacking: data.openingRepackingCharges,
        seal: data.sealCharges,
        unloading: data.unloadingCharges,
        whn: data.whnCharges,
        pvtWhTransport: data.pvtWhTransportCharges,
        pvrtMoveWh: data.pvrtMoveWhCharges,
        stickerPrinting: data.stickerPrintingCharges,
        mmd3: data.mmd3Charges,
        fumigation: data.fumigationCharges,
      };
      if (data.type === "FCL") {
        data.cfsAgency = null;
      } else {
        if (data.cfsAgency === "Others") {
          data.cfsAgency = cfsOtherValue;
        } else {
          data.cfsAgency = data.cfsAgency;
        }
      }
      if (data.leoDate === "") {
        data.leoDate = null;
      }
      // const customData = {
      //   ...data,
      //   cfsAgency: data.cfsAgency === "Others" ? cfsOtherValue : data.cfsAgency,
      // };
      console.log("customData", data);
      dispatch(updateNewStatusReportEntry({ id: editData.id, data: data }));

      return;
    }

    // dispatch(addNewStatusReportEntry(customData));
  };

  const returnCommaStringToArray = (input) => {
    return input.split(",").map((item) => item.trim());
  };

  return (
    <Dialog open={open} fullWidth maxWidth={"lg"}>
      <DialogTitle sx={{ m: 0, p: 1.5, borderBottom: "solid 1px #AAAFDD6B" }}>
        {editData == null ? "Add" : "Edit"} Entry
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ marginTop: 2 }}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "24px",
              // '& .MuiTextField-root': { m: 1, width: '100%' },
              "& .MuiFormControl-root": { m: 1, width: "100%" },
              "& .MuiInputBase-input ": {
                backgroundColor: "#FBFBFB",
                borderColor: "#DBDDF1",
                borderRadius: 8,
              },
            }}
          >
            {entryFields.map((val) => {
              if (
                val.fieldName == "type" ||
                (val.fieldName == "numberOfPackages" && watch("type") == "FCL")
              )
                return (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={!!errors[val.fieldName]}
                  >
                    <InputLabel id={`${val.label}-label`}>
                      {val.label}
                    </InputLabel>
                    <Controller
                      name={val.fieldName}
                      control={control}
                      rules={{ required: `${val.label} is required` }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          required={val.required}
                          fullWidth
                          margin="dense"
                          id={val.fieldName}
                          label={val.label}
                          placeholder={`Enter ${val.label}`}
                          variant="outlined"
                          value={value}
                          onChange={onChange}
                          labelId={`${val.label}-label`}
                          {...register(val.fieldName, {
                            required: val.required
                              ? `${val.label} is required`
                              : null,
                          })}
                        >
                          {val.fieldName == "type"
                            ? typeValues.map((e) => (
                                <MenuItem value={e}>{e}</MenuItem>
                              ))
                            : fclNumberOfPackagesValue.map((e) => (
                                <MenuItem value={e}>{e}</MenuItem>
                              ))}
                        </Select>
                      )}
                      defaultValue=""
                    />
                    {!!errors[val.fieldName] && (
                      <FormHelperText>
                        {errors[val.fieldName]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              else if (val.fieldName == "placeOfDischarge")
                return (
                  <Autocomplete
                    freeSolo
                    id="combo-box-demo"
                    options={placesOfDischarge}
                    value={watch(val.fieldName)}
                    // sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={val.label}
                        id={val.fieldName}
                        placeholder={`Enter ${val.label}`}
                        fullWidth
                        variant="outlined"
                        {...register(val.fieldName, {
                          required: val.required
                            ? `${val.label} is required`
                            : null,
                        })}
                        error={!!errors[val.fieldName]}
                        helperText={errors[val.fieldName]?.message}
                      />
                    )}
                  />
                );
              else if (val.fieldName === "cfsAgency") {
                return (
                  watch("type") !== "FCL" && (
                    <>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={!!errors[val.fieldName]}
                      >
                        <InputLabel id={`${val.label}-label`}>
                          {val.label}
                        </InputLabel>
                        <Controller
                          name={val.fieldName}
                          control={control}
                          rules={{ required: `${val.label} is required` }}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <Select
                                required={val.required}
                                fullWidth
                                margin="dense"
                                id={val.fieldName}
                                label={val.label}
                                placeholder={`Enter ${val.label}`}
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                labelId={`${val.label}-label`}
                                {...register(val.fieldName, {
                                  required: val.required
                                    ? `${val.label} is required`
                                    : null,
                                })}
                              >
                                {agencyValues.map((e) => (
                                  <MenuItem value={e}>{e}</MenuItem>
                                ))}
                              </Select>
                            </>
                          )}
                          defaultValue=""
                        />

                        {!!errors[val.fieldName] && (
                          <FormHelperText>
                            {errors[val.fieldName]?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                      {watch("cfsAgency") === "Others" && (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="cfsAgency"
                          value={cfsOtherValue}
                          onChange={(e) => setCfsOther(e.target.value)}
                          required={true}
                          label="CFS Agency Others"
                          placeholder={`Enter ${val.label}`}
                          fullWidth
                          variant="outlined"
                          error={!!errors[val.fieldName]}
                          helperText={errors[val.fieldName]?.message}
                        />
                      )}
                    </>
                  )
                );
              } else if (val.fieldName === "leoDate")
                return (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={!!errors[val.fieldName]}
                  >
                    {/* <InputLabel id={`${val.label}-label`}>{val.label}</InputLabel> */}
                    <Controller
                      name={val.fieldName}
                      control={control}
                      // rules={{ required: `${val.label} is required` }}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          style={{ margin: 0 }}
                          control={
                            <DatePicker
                              name="leoDate"
                              fullWidth
                              size="large"
                              placeholder={val.label}
                              defaultValue={
                                editData?.leoDate &&
                                dayjs(new Date(editData?.leoDate))
                              }
                              getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode;
                              }}
                              onChange={(_, dateStr) => onChange(dateStr)}
                              style={{
                                width: "100%",
                                height: "56px",
                                backgroundColor: "#FBFBFB",
                              }}
                            />
                          }
                          // label={val.label}
                        />
                      )}
                    />
                    {!!errors[val.fieldName] && (
                      <FormHelperText>
                        {errors[val.fieldName]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              else if (val.fieldName == "measurementCheck")
                return (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={!!errors[val.fieldName]}
                  >
                    {/* <InputLabel id={`${val.label}-label`}>{val.label}</InputLabel> */}
                    <Controller
                      name={val.fieldName}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value}
                              onChange={onChange}
                            ></Checkbox>
                          }
                          label={val.label}
                        ></FormControlLabel>
                      )}
                    />
                    {!!errors[val.fieldName] && (
                      <FormHelperText>
                        {errors[val.fieldName]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              else
                return (
                  <TextField
                    autoFocus
                    margin="dense"
                    id={val.fieldName}
                    // disabled={val.autoCharge && watch(val.fieldName) != ""}
                    // value={watch(val.fieldName)}
                    required={val.required}
                    label={val.label}
                    placeholder={`Enter ${val.label}`}
                    fullWidth
                    variant="outlined"
                    {...register(val.fieldName, {
                      required: val.required
                        ? `${val.label} is required`
                        : null,
                    })}
                    error={!!errors[val.fieldName]}
                    helperText={errors[val.fieldName]?.message}
                  />
                );
            })}
          </Box>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton
              loading={loading}
              className="app-button-small"
              type="submit"
              variant="contained"
              color="primary"
            >
              Save Entry
            </LoadingButton>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddOrEditEntryDialog;
