import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userPool from "../helper/config/userPool";
import axios from "axios";
import { ApiStatics } from "../helper/util/apiStatics";

const initialState = {
  data: {
    name: "",
    phone: "",
    profilePhoto: "",
  },
  users: [],
  loading: false,
  total: 0,
  actionLoading: false,
  error: null,
  success: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addLoggedInUsersData(state, action) {
      state.data = action.payload;
    },
    clearUserSession(state, action) {
      window.location.href = "/";
      state = initialState;
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        console.log(action.payload);
        if (!action.payload.success) {
          state.loading = false;
          state.error = action.payload.error;
          return;
        }
        let { data, token } = action.payload;
        localStorage.setItem("data", JSON.stringify(data));
        localStorage.setItem("token", token);
        state.loading = false;
        state.data = data;
        state.success = true;
      })
      .addCase(loginUser.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      });
    builder
      .addCase(addUser.fulfilled, (state, action) => {
        if (action.payload?.success) {
          state.actionLoading = false;
        } else {
          state.tokenExpired = true;
          state.actionLoading = false;
        }
      })
      .addCase(addUser.pending, (state, action) => {
        state.actionLoading = true;
        //  state.tableLoading = true;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.actionLoading = false;
        //  state.tableLoading = true;
      });
    builder
      .addCase(blockUser.fulfilled, (state, action) => {
        if (action.payload?.success) {
          state.actionLoading = false;
        } else {
          state.tokenExpired = true;
        }
      })
      .addCase(blockUser.pending, (state, action) => {
        state.actionLoading = true;
        //  state.tableLoading = true;
      })
      .addCase(blockUser.rejected, (state, action) => {
        state.actionLoading = false;
        //  state.tableLoading = true;
      });
    builder
      .addCase(getAllUsers.fulfilled, (state, action) => {
        if (action.payload?.success) {
          state.loading = false;
          state.users = action.payload.data;
          state.total = action.payload.total;
        } else {
          state.tokenExpired = true;
        }
      })
      .addCase(getAllUsers.pending, (state, action) => {
        state.loading = true;
        //  state.tableLoading = true;
      });
  },
});

export const addUser = createAsyncThunk(
  "user/addUser",
  async ({ values, toast, handleClose }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/${ApiStatics.users.users}`,
        values,
        config
      );
      toast.success("Employee added successfully");
      setTimeout(() => {
        handleClose();
      }, 3000);
      return {
        success: true,
      };
    } catch (error) {
      toast.error("Employee can not be added");
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
export const blockUser = createAsyncThunk(
  "user/blockUser",
  async ({ id, toast, handleClose }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/${ApiStatics.users.users}/${id}/block`,
        {},
        config
      );
      toast.success("Employee blocked successfully");
      setTimeout(() => {
        handleClose();
      }, 3000);
      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
export const getAllUsers = createAsyncThunk(
  "user/getAllUsers",
  async ({ page, limit }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      let resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/${ApiStatics.users.users}?${
          page ? `page=${page - 1}` : ""
        }${limit ? `&limit=${limit}` : ""}`,
        config
      );

      // let offset = page * limit;
      return {
        success: resp.data.status === "success",
        data: resp.data.data.data,
        total: resp.data.total,
      };
    } catch (error) {
      console.error("Error", error);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);

export const loginUser = createAsyncThunk("user/login", async (data) => {
  let { email, password } = data;
  console.log(email, password);
  try {
    let data = await handleAwsAuthentication(email, password);
    return {
      success: true,
      data: data.getIdToken().payload,
      token: data.getIdToken().getJwtToken(),
    };
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
});

const handleAwsAuthentication = (email, password) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: resolve,
      onFailure: reject,
    });
  });
};

export default userSlice.reducer;
export const { addLoggedInUsersData, clearUserSession } = userSlice.actions;
// export const userSlice.reducer
