import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import block from "../../assets/block-blue.svg";
import { useDispatch, useSelector } from "react-redux";
import { blockUser } from "../../store/userSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BlockEmployee = ({ id, open, handleClose }) => {
  const { actionLoading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleBlockUser = () => dispatch(blockUser({ id, toast, handleClose }));
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="Add/Edit Employee"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className="block-dialog-content">
        <div className="block-dialog-header">
          <CloseIcon onClick={handleClose} className="pointer" />
        </div>
        <div className="block-body">
          <img src={block} width="80px" alt="block-user" />
          <h6 className="block-description">
            You want to block this employee profile?
          </h6>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: "24px" }}>
        <Button>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleBlockUser}
          disabled={actionLoading}
        >
          Yes, Block
        </Button>
      </DialogActions>
      <ToastContainer />
    </Dialog>
  );
};

export default BlockEmployee;
