import { Skeleton } from "@mui/material";
import "./Dashboard.css";
import {
  getFormattedCurrency,
  getFormattedNumber,
} from "../../helper/util/common";

const StatContainer = ({ color, icon, title, stat, loading, currency }) => {
  const currencyChecker = () => {
    if (currency) {
      return getFormattedCurrency(stat);
    } else return getFormattedNumber(stat);
  };
  return (
    <div className="stat-div">
      <div className="stat-icon-container" style={{ backgroundColor: color }}>
        <img src={require(`../../assets/stats/${icon}`)} />
      </div>
      <div className="stat-detail-container">
        <h6>{title}</h6>
        {loading ? (
          <Skeleton animation="wave" height={39} />
        ) : (
          <h4 className="stat-content-text">
            {title == "Star Client" ? stat : currencyChecker()}
          </h4>
        )}
      </div>
    </div>
  );
};

export default StatContainer;
