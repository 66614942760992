import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { Checkbox, DatePicker } from "antd";
import {
  reportValuesCharges,
  reportValuesEntries,
} from "../../helper/data/static";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadSummaryReport,
  updateSummaryReportVars,
} from "../../store/statusReportSlice";

const { RangePicker } = DatePicker;

export const DownloadSummaryDialog = ({ open, handleClose }) => {
  const [lifeTimeCheck, setLifeTimeCheck] = useState(false);
  const [customReportCheck, setCustomReportCheck] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);

  const [selectedEntryCheckBoxes, setSelectedEntryCheckboxes] = useState([]);
  const [selectedChargesCheckBoxes, setSelectedChargesCheckboxes] = useState(
    []
  );

  const { loaders, feedback } = useSelector((state) => state.statusReports);

  const dispatch = useDispatch();

  const handleDownload = () => {
    dispatch(
      downloadSummaryReport({
        custom: customReportCheck,
        dates: lifeTimeCheck ? null : selectedDates,
        charges: selectedChargesCheckBoxes,
        entries: selectedEntryCheckBoxes,
      })
    );
  };

  useEffect(() => {
    if (feedback.summaryReportAvailable) {
      window.open(feedback.summaryReportUrl, "_blank");
      dispatch(updateSummaryReportVars());
      handleClose();
    }
  }, [feedback.summaryReportAvailable]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          minHeight: 500,
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 1.5, borderBottom: "solid 1px #AAAFDD6B" }}>
        Download Summary Report
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: 2,
          gap: "24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Checkbox
          value={lifeTimeCheck}
          onChange={(e) => setLifeTimeCheck(e.target.checked)}
        >
          Lifetime
        </Checkbox>
        {!lifeTimeCheck && (
          <RangePicker
            allowClear
            style={{ width: "50%" }}
            getPopupContainer={(triggerNode) => {
              //Required to run AntD RangePicker on MUI Dialog
              return triggerNode.parentNode;
            }}
            format={"DD MMM YYYY"}
            onCalendarChange={setSelectedDates}
            value={selectedDates}
            onChange={(props) => {
              if (props == null) {
                setSelectedDates([]);
              }
            }}
          />
        )}
        <Checkbox
          value={customReportCheck}
          onChange={(e) => setCustomReportCheck(e.target.checked)}
        >
          Custom
        </Checkbox>
        {customReportCheck && (
          <>
            <Checkbox.Group
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "24px",
              }}
              options={reportValuesEntries}
              onChange={setSelectedEntryCheckboxes}
            />
            <Checkbox.Group
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "24px",
              }}
              options={reportValuesCharges}
              onChange={setSelectedChargesCheckboxes}
            />
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {!loaders.downloadSummaryReport && (
          <Button onClick={handleClose}>Cancel</Button>
        )}
        <LoadingButton
          loading={loaders.downloadSummaryReport}
          className="app-button-small"
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleDownload}
        >
          Download
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
