import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import Navbar from "../Navbar/Navbar";
import "./Dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { statsContainerData } from "../../helper/data/static";
import StatContainer from "./StatContainer";
import ChartContainer from "./ChartContainer";
import { LoadingButton } from "@mui/lab";
import FiltersIcon from "../../assets/filters.svg";
import DownloadIcon from "../../assets/download.svg";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import editIcon from "../../assets/edit.svg";
import DownloadInvoiceIcon from "../../assets/download-invoice.svg";
import CreateInvoiceIcon from "../../assets/create-invoice.svg";
import ApproveEntryIcon from "../../assets/approve-entry.svg";
import { clearUserSession } from "../../store/userSlice";
import {
  approveEntry,
  createInvoiceFromEntry,
  createMultiInvoices,
  downloadEntryInvoice,
  getAllStatusReportEntries,
  getPlacesOfDischarge,
  searchStatusReport,
  updateDateFilter,
  updateFilterTrigger,
  updateStatusFilter,
} from "../../store/statusReportSlice";
import moment from "moment";
import AddOrEditEntryDialog from "./AddOrEditEntryDialog";
import { Button, Popover, DatePicker, Collapse, Table, Tag, Modal } from "antd";
import { getCharts, getStats } from "../../store/statsSlice";
import { DownloadSummaryDialog } from "./DownloadSummaryDialog";
import { getFormattedCurrency } from "../../helper/util/common";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

const { RangePicker } = DatePicker;

const Dashboard = () => {
  const location = useLocation();
  const [selectedIds, setSelectedIds] = useState([]);
  const paramPage = Number(location.search.split("=")[1]);
  const dispatch = useDispatch();
  const [page, setPage] = useState(paramPage);
  const [query, setQuery] = useState("");

  const [limit, setLimit] = useState(30);
  const { name } = useSelector((state) => state.user.data);
  const {
    list: entries,
    success,
    tokenExpired,
    filters,
    loaders,
    loading,
    total,
  } = useSelector((state) => state.statusReports);
  const [isEditInvoiceDialog, setInvoiceDialog] = useState(false);
  const [invoiceId, setId] = useState("");
  const [internalInvoiceNumber, setNumber] = useState("");
  const navigate = useNavigate();
  const handleCreateId = (id, invNumber) => {
    setNumber(invNumber);
    setId(id);
    setInvoiceDialog(true);
  };
  const handleCreateMultiple = (id, invNumber) => {
    // setNumber(invNumber);
    // setId(id);
    setInvoiceDialog(true);
  };
  const handleCancel = () => setInvoiceDialog(false);
  const handleSubmitInvoice = () =>
    dispatch(
      createInvoiceFromEntry({
        id: invoiceId,
        internalInvoiceNumber,
        setInvoiceDialog,
      })
    );

  const { tableActions } = loaders;

  const statsData = useSelector((state) => state.stats);
  const { loading: statsLoading, chart: statChart } = statsData;

  const [editEntryModalOpen, setEditEntryModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);

  const [downloadReportModalOpen, setDownloadReportModalOpen] = useState(false);
  const [debouncedValue] = useDebounce(query, 800);

  const statusList = ["Pending", "Approved", "Invoiced"];
  const handleSearch = (e) => setQuery(e.target.value);
  const handlePage = (newPage) => {
    setSelectedIds([]);
    setPage(newPage);
    navigate({
      pathname: "/dashboard",
      search: `?${createSearchParams({
        page: newPage,
      })}`,
    });
  };
  const filterMenu = [
    {
      key: "1",
      label: "Status",
      children: (
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          onChange={(e) => {
            dispatch(updateStatusFilter(e.target.value));
            getStatusReportsFresh({ status: e.target.value });
          }}
        >
          <FormControlLabel
            value="PENDING"
            control={<Radio />}
            label="Pending"
          />
          <FormControlLabel
            value="APPROVED"
            control={<Radio />}
            label="Approved"
          />
          <FormControlLabel
            value="INVOICED"
            control={<Radio />}
            label="Invoiced"
          />
          <FormControlLabel value={null} control={<Radio />} label="All" />
        </RadioGroup>
      ),
    },
    // {
    //   key: '2',
    //   label: 'Status',
    //   children: <Checkbox.Group options={statusList} style={{ flexDirection: 'column' }} />,
    // },
    // {
    //   key: '3',
    //   label: 'This is panel header 3',
    //   children: <p>{text}</p>,
    // },
  ];

  const content = (
    <div>
      <Collapse
        accordion
        items={filterMenu}
        collapsible="header"
        style={{ width: "100%" }}
        defaultActiveKey={"1"}
      />
    </div>
  );

  const [successSnackbar, setSuccessSnackbar] = useState(false);

  const handleDateChanged = (dates) => {
    // console.log(dates != null && dates[1] != null && dates[2] != null)
    console.log("dates", dates);
    if (dates == null) {
      setPage(1);
      dispatch(updateDateFilter([]));
      getStatusReportsFresh({});
      return;
    }
    if (dates[0] != null && dates[1] != null) {
      console.log("coallingdate change", dates);
      const startDate = dates[0];
      const endDate = dates[1];
      setPage(1);
      dispatch(updateDateFilter(dates));
      dispatch(updateFilterTrigger("dateFilter"));
      getStatusReportsFresh({ startDate, endDate });
    }
  };

  const getStatusReportsFresh = ({ startDate, endDate, status }) => {
    dispatch(
      getAllStatusReportEntries({
        page,
        limit,
        startDate,
        endDate,
        status,
        financialYear: filters.financialYear,
      })
    );
  };

  const searchReports = () => dispatch(searchStatusReport({ query }));

  useEffect(() => {
    if (query !== "") {
      searchReports();
    } else {
      const startDate =
        filters.financialYear === "24 - 25"
          ? "2024-04-01T11:25:56.908Z"
          : "2023-04-01T11:25:56.908Z";
      const endDate =
        filters.financialYear === "24 - 25"
          ? "2025-03-31T11:25:56.908Z"
          : "2024-03-31T11:25:56.908Z";

      dispatch(getStats({ startDate, endDate }));
      dispatch(getCharts({ startDate, endDate }));
      dispatch(getPlacesOfDischarge());
      filters.trigger === "finYear"
        ? getStatusReportsFresh({ startDate, endDate })
        : getStatusReportsFresh({
            startDate: filters.dates[0],
            endDate: filters.dates[1],
          });
    }
  }, [page, limit, debouncedValue, filters?.financialYear]);
  useEffect(() => {
    const startDate =
      filters.financialYear === "24 - 25"
        ? "2024-04-01T11:25:56.908Z"
        : "2023-04-01T11:25:56.908Z";
    const endDate =
      filters.financialYear === "24 - 25"
        ? "2025-03-31T11:25:56.908Z"
        : "2024-03-31T11:25:56.908Z";

    getStatusReportsFresh({ startDate, endDate });
  }, [filters.financialYear]);

  const handleClickOpen = () => {
    setEditEntryModalOpen(true);
  };

  const handleClose = () => {
    setEditEntryModalOpen(false);
    setEditData(null);
  };
  // useEffect(() => {
  //   if (entries) {
  //     if (entries.length === 0 && page > 0) {
  //       setPage(1);
  //     }
  //   }
  // }, [entries]);

  useEffect(() => {
    if (tokenExpired) {
      dispatch(clearUserSession());
      handleClose();
    }
  }, [tokenExpired]);

  useEffect(() => {
    console.log("setting page", Number(location.search.split("=")[1]));
    setPage(paramPage);
  }, []);

  useEffect(() => {
    if (success) {
      getStatusReportsFresh({});
      setSuccessSnackbar(true);
      handleClose();
    }
  }, [success]);

  const dataEntryTableColumns = [
    {
      title: "sNo",
      dataIndex: "sNo",
      // render: (createdAt) => moment(createdAt).format("DD MMM yyyy"),
    },
    {
      title: "Added On",
      dataIndex: "createdAt",
      render: (createdAt) => moment(createdAt).format("DD MMM yyyy"),
    },
    {
      title: "Int. Inv. No.",
      dataIndex: "invoice",
      render: ({ invoiceNumber }) => invoiceNumber != null && invoiceNumber,
    },
    {
      title: "Total W/O GST",
      dataIndex: "invoice",
      render: ({ totalWithoutGst }) =>
        totalWithoutGst != null && getFormattedCurrency(totalWithoutGst),
    },
    {
      title: "GST",
      dataIndex: "invoice",
      render: ({ gst }) => gst != null && getFormattedCurrency(gst),
    },
    {
      title: "INV No.",
      dataIndex: "invoiceNumbers",
      render: (val) => val.join(", "),
    },
    // {
    //   title: "No. Of Packages",
    //   dataIndex: "numberOfPackages",
    // },
    // {
    //   title: "Third Party Company",
    //   dataIndex: "thirdPartyCompany",
    // },
    // {
    //   title: "Shipping Bill Number(s)",
    //   dataIndex: "shippingBillNumbers",
    // },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag className={`${status.toLowerCase()}`}>{status.toUpperCase()}</Tag>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      render: (row) => (
        <>
          <div style={{ display: "flex", gap: "20px" }}>
            <Tooltip title="Edit Entry">
              <img
                onClick={() => {
                  setEditData(row);
                  handleClickOpen();
                }}
                style={{ cursor: "pointer" }}
                src={editIcon}
                alt="edit"
              />
            </Tooltip>
            {row.status === "PENDING" && (
              <Tooltip title="Approve Entry">
                <img
                  onClick={() => {
                    dispatch(approveEntry({ id: row.id }));
                  }}
                  style={{ cursor: "pointer" }}
                  src={ApproveEntryIcon}
                  alt="approve"
                />
              </Tooltip>
            )}
            {row.status === "APPROVED" && (
              <Tooltip title="Create Invoice">
                <img
                  onClick={() =>
                    handleCreateId(row.id, row.invoice.invoiceNumber)
                  }
                  style={{ cursor: "pointer" }}
                  src={CreateInvoiceIcon}
                  alt="approve"
                />
              </Tooltip>
            )}
            {row.status === "INVOICED" && (
              <Tooltip title="Download Invoice">
                <img
                  onClick={() => {
                    dispatch(
                      downloadEntryInvoice({
                        id: row.invoice.id,
                      })
                    );
                  }}
                  style={{ cursor: "pointer" }}
                  src={DownloadInvoiceIcon}
                  alt="download"
                />
              </Tooltip>
            )}
          </div>
        </>
      ),
    },
  ];
  const rowSelection = {
    type: "checkbox",
    // defaultValue: selectedIds,
    onChange: (selectedRowKeys, selectedRows) =>
      setSelectedIds(selectedRowKeys),
    getCheckboxProps: (record) => ({
      checked: selectedIds.includes(record.id),

      disabled: record.status === "INVOICED" || record.status === "APPROVED",
      // Column configuration not to be checked
      status: record.status,
    }),
  };
  console.log("sele", selectedIds);
  const invoiceAll = () => {
    const idsToSend = [];
    entries.map(({ id }) => {
      selectedIds.map((selectedId) => {
        if (id === selectedId) {
          idsToSend.push(selectedId);
        }
      });
    });
    dispatch(
      createMultiInvoices({
        ids: idsToSend.reverse(),
        setInvoiceDialog,
        internalInvoiceNumber,
        navigate,
      })
    );
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Navbar />
      <div className="dashboard-container">
        <h1>Welcome {name}</h1>
        <div className="stats-charts-container">
          <div style={{ width: "50%" }}>
            <div className="stats-container">
              {statsContainerData.map((data) => (
                <StatContainer
                  currency={data.currency}
                  icon={data.icon}
                  title={data.title}
                  color={data.color}
                  stat={statsData[data.dataKey]}
                  loading={statsLoading}
                />
              ))}
            </div>
          </div>
          <div style={{ height: "374px", width: "50%" }}>
            <ChartContainer data={statChart} statsLoading={statsLoading} />
          </div>
        </div>
        <div className="table-container">
          <div className="table-container-header">
            <h6>All Data Entries</h6>
            <div className="table-container-header-button-container">
              {selectedIds.length > 0 && (
                <LoadingButton
                  className="app-button-small button-extra-padding"
                  variant="contained"
                  color="primary"
                  onClick={handleCreateMultiple}
                >
                  {/* <img
                    style={{ cursor: "pointer" }}
                    src={CreateInvoiceIcon}
                    alt="approve"
                  /> */}
                  Invoice All
                </LoadingButton>
              )}
              <TextField
                value={query}
                onChange={handleSearch}
                placeholder="Search..."
              />
              <RangePicker
                allowClear
                format={"DD MMM YYYY"}
                onCalendarChange={handleDateChanged}
                // value={filters.dates}
                onChange={(props) => {
                  if (props == null) {
                    handleDateChanged(null);
                  }
                }}
                // placeholder={["Some", 'Thing']}
              />
              {/* <LoadingButton loading={false} className='app-button-secondary-small button-extra-padding' variant='text' color='secondary'>
                12 Sep, 2023 - 12 Oct, 2023
                <img src={DateFilterIcon} />
              </LoadingButton> */}
              <Popover placement="bottom" content={content} trigger="click">
                <Button
                  className="app-button-secondary-small button-extra-padding"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={FiltersIcon} />
                  Filter
                </Button>
              </Popover>
              <LoadingButton
                loading={false}
                className="app-button-small button-extra-padding"
                variant="contained"
                color="primary"
                onClick={() => setDownloadReportModalOpen(true)}
              >
                <img src={DownloadIcon} />
                Download Summary
              </LoadingButton>
            </div>
          </div>
          <div className="app-divider" />
          <div className="table-container-table">
            <div className="status-report-table">
              <Table
                columns={dataEntryTableColumns}
                dataSource={entries}
                rowSelection={rowSelection}
                pagination={{
                  position: ["topRight"],
                  defaultPageSize: 30,
                  total,
                  current: page,
                  onChange: (newPage) => handlePage(newPage),
                  onShowSizeChange: (e, value) => setLimit(value),
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ["10", "20", "30"],
                  style: {
                    position: "sticky",
                    top: "53px",
                    zIndex: "23",
                    backgroundColor: "white",
                    marginTop: "0px",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                  },
                }}
                sticky={true}
                loading={loading}
              />
              <div
                style={{
                  width: "95%",
                  display: "flex",
                  justifyContent: "end",
                  flexDirection: "column",
                }}
              ></div>
            </div>
          </div>
        </div>
        {editEntryModalOpen && (
          <AddOrEditEntryDialog
            open={editEntryModalOpen}
            handleClose={handleClose}
            editData={editData}
          />
        )}
        {downloadReportModalOpen && (
          <DownloadSummaryDialog
            open={downloadReportModalOpen}
            handleClose={() => setDownloadReportModalOpen(false)}
          />
        )}
      </div>
      <Modal
        title="Edit Invoice Number"
        open={isEditInvoiceDialog}
        onOk={selectedIds.length > 0 ? invoiceAll : handleSubmitInvoice}
        onCancel={handleCancel}
        okButtonProps={{ disabled: loading }}
      >
        <TextField
          value={internalInvoiceNumber}
          onChange={(e) => setNumber(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default Dashboard;
